import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'redux/store'

import App from './components/App'
// import RouterBack from 'components/common/RouterBack'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'
import RouteTracker from 'redux/routeTracker'

import 'styles/index.css'

// Ініціалізація Sentry має бути винесена з компонента
const isLocalhost = window.location.hostname === 'localhost'
if (!isLocalhost) {
  Sentry.init({
    dsn: 'https://61b3aebf1e3cd37cd1755c60b7244754@sentry.marksel.tech/5',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: false,
  })
}

const MyComponent = () => {
  const navigate = useNavigate()

  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const handleError = () => {
      setHasError(true)
    }

    window.addEventListener('error', handleError)

    return () => {
      window.removeEventListener('error', handleError)
    }
  }, [])

  // if (hasError) {
  //   return (
  //     <div
  //       onClick={() => {
  //         navigate(-1)
  //         setHasError(false)
  //       }}
  //       className="wrap-error"
  //     >
  //       Something went wrong.
  //       <RouterBack />
  //     </div>
  //   )
  // }

  return <App />
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <RouteTracker />
      <ErrorBoundary fallback={<div>Something went wrong.</div>}>
        <MyComponent />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
)
